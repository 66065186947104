<template>
	<section class="widget-content">
		<article class="widget-text-block">

				<medium-editor
				:options='options'
					v-for="(paragraph, index) in paragraphs"
					:key="paragraph.id"
					label="Text"
					auto-grow
					outlined
					rows="10"
					row-height="25"
					class="textinput"
					v-model="paragraph.content"

				>
					<template #label>Paragraph #{{ index + 1 }}</template>
				</medium-editor>

			<v-btn
				@click="addParagraph"
				class="admin-primary-button secondary-contrast-background primary-outline mr-5 mb-5"
				>Add line</v-btn
			>

		</article>
		<article>
			<form-message v-if="message" :type="widgetMessageStatus">{{
				message
			}}</form-message>
		</article>

		<div class="widget-bullet-list__button">

<v-btn
	class="w-100 admin-primary-button primary-contrast-background"
	:loading="isLoading"
	@click="submit"
	>Done</v-btn
>
</div>

	</section>
</template>

<script>
import MediumEditor from 'vuejs-medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
import 'vuejs-medium-editor/src/themes/default.css'
import 'highlight.js/styles/ocean.css'

import FormMessage from "@/components/FormMessage.vue";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

import TurndownService from 'turndown'
const turndownService = new TurndownService()
export default {
	name: "WidgetPopupTextBlock",
	components: { FormMessage, MediumEditor },
	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		editedBlockId() {
			return this.editedBlock?.id;
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			paragraphs: [],

	options: {
        toolbar: {
          buttons: ["bold", "italic", "underline"]
        },
		placeholder: false
      }

		};
	},
	watch: {
		editedBlockId: function () {
			this.initGetData();
		}
	},

	methods: {
		addParagraph(id = uuidv4(), content = "", isNew = true) {
			this.paragraphs.push({
				id: id,
				content: content,
				isNew: isNew
			});
		},
		initGetData() {
			if (this.editedBlock === null) {
				this.addParagraph();
			} else {
				this.editedBlock.block.paragraphs.forEach(el =>
					this.addParagraph(el.id, el.content, false)
				);
			}
		},
		async submit() {

			this.paragraphs.forEach((i,v) => {
				this.paragraphs[v].content = turndownService.turndown(i.content)
			})

			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: Number(this.$route.params.id),
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								title: this.editedBlock.block.title,
								content: this.editedBlock.block.content,
								head_level: this.editedBlock.block.head_level,
								css_class: this.editedBlock.block.css_class,
								sort_order: this.editedBlock.block.sort_order
						  }
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								title: "",
								content: "",
								head_level: 2,
								css_class: null,
								sort_order:
									Number(this.activePage.blocks.length) + 1
						  },
				blkType: this.currentWidget.blk_type,
				intro: this.currentWidget.intro,
				elements: this.paragraphs
			};
			this.$emit("saveBlock", payload);
		}
	},
	mounted() {
		this.initGetData();
	},
};
</script>

<style lang="scss">
.insert-image-container {
	display: none !important;
}
.textinput {
	border: 1px solid #333;
	margin-bottom: 20px;
}

.medium-editor-container {
	padding: 15px 20px;

	p {
		margin-bottom: 0;
	}
}
</style>
